<template>
  <v-app-bar dark app :src="background_image">
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(10,10,10,.5), rgba(90,90,90,.8)"
      ></v-img>
    </template>

    <v-snackbar
      :timeout="notification_timeout"
      v-model="notification_snackbar"
      :color="notification_severity"
      top
    >
      <v-row>
        <v-col cols="2">
          <v-icon
            v-if="!notification || !notification.avatar_id"
            class="mr-2"
            color="white"
            >mdi-bell</v-icon
          >
          <div v-if="notification && notification.avatar_id">
            <div class="profile-container mr-2">
              <div
                v-if="notification.gravatar"
                class="profile-gravatar"
                v-html="buildGravatar(notification)"
              ></div>
            </div>
          </div>
        </v-col>
        <v-col class="ml-2">
          {{ notification ? notification.content : "" }}
        </v-col>
        <v-col cols="2" class="text-right">
          <v-icon
            class="ml-2"
            v-if="
              notification
                ? !notification.persistent && !notification.acked
                : false
            "
            color="light-green"
            @click="ackNotification()"
            >mdi-check</v-icon
          >

          <v-icon
            class="ml-2"
            v-if="notification ? !notification.persistent : false"
            color="red"
            @click="removeNotification()"
            >mdi-delete-circle-outline</v-icon
          >
          <v-icon class="ml-2" color="white" @click="closeNotification()"
            >mdi-close-circle</v-icon
          >
        </v-col>
      </v-row>
    </v-snackbar>
    <v-btn
      fab
      x-small
      @click="
        $vuetify.breakpoint.smAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>
      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <span class="yeast-logo-span"
      ><img class="forno-logo" src="../assets/forno_logo_bianco.png"
    /></span>

    <span class="header-copyright">{{
      $t("yeast-copy", { version: packageInfo.version })
    }}</span>

    <v-spacer />

    <div class="mx-3" />
    <span class="header-welcome">
      {{ $t("yeast-welcome", { name: $store.state.user.first_name }) }}
    </span>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge v-if="hasValidNotifications()" color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{ notificationBadgeNumber }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <v-icon v-if="!hasValidNotifications()">mdi-bell</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title @click="processNotification(n)"
              ><v-icon
                v-if="!n.acked && n.valid"
                class="mr-2"
                x-small
                color="red"
                >mdi-circle</v-icon
              >{{ n.title }}</v-list-item-title
            >
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-tooltip v-if="!$store.state.spooler_online" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">
          mdi-printer-off
        </v-icon>
      </template>
      <span>Spooler di stampa offline</span>
    </v-tooltip>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      v-if="$store.state.spooler_online"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="orange" overlap bordered>
            <template v-slot:badge>
              <span>{{ $store.state.spooler_num_of_copies }}</span>
            </template>

            <v-icon>mdi-printer-check</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item
            v-for="(n, i) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            :key="`item-${i}`"
          >
            <v-list-item-title @click="selectPrinterSpoolCopies(n)">{{
              n
            }}</v-list-item-title>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <template v-for="(p, i) in profile">
          <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />
          <app-bar-item v-else :key="`item-${i}`">
            <v-list-item-title v-text="p.title" @click="handleClick(p)" />
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<style>
.appbar {
  .v-toolbar__content {
    height: 120px;
  }
}
</style>

<style scoped>
.yeast-logo-span {
  margin-right: 10px;
}

.yeast-logo {
  max-height: 50px;
  margin-left: 5px;
  margin-right: 5px;
}

.forno-logo {
  max-height: 50px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.header-copyright {
  text-decoration: none;
  text-transform: uppercase;
}

.header-welcome {
  text-decoration: none;
  text-transform: uppercase;
}

@media screen and (max-width: 800px) {
  .v-app-bar {
    font-size: 12px;
  }

  .forno-logo {
    max-height: 30px;
  }
  .header-copyright {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .v-app-bar {
    font-size: 12px;
  }

  .header-welcome {
    display: none;
  }

  .header-copyright {
    display: none;
  }
}
</style>

<script>
import { VHover, VListItem } from "vuetify/lib";
import { mapState, mapMutations } from "vuex";

import notificationsManager from "../apis/notifications";
import localStorageService from "../services/localStorageService";
import AvatarServices from "../services/avatars";

const packageInfo = require("../../package.json");

export default {
  name: "CoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              );
            }
          }
        });
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.profile = [
      { title: this.$t("header-profile"), url: "/profile" },
      { divider: true },
      { title: this.$t("header-logout"), func: this.logout }
    ];
  },

  data: () => ({
    profile: [],
    notification_snackbar: false,
    notification_timeout: 60000,
    notification: null,
    notification_severity: "primary",
    incCounterRecomputer: 0,
    packageInfo: packageInfo,
    background_image: require("../assets/header.jpg")
  }),

  methods: {
    selectPrinterSpoolCopies(n) {
      this.$store.dispatch("spoolerSetNumOfCopies", { copies: n });
    },

    buildGravatar: function(notification) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        notification.gravatar +
        "'>"
      );
    },

    checkGravatar: function(notification) {
      return new Promise((resolve, reject) => {
        if (!notification.gravatarFetched) {
          notification.gravatarFetched = true;
          AvatarServices.fetchAvatar(notification.avatar_id)
            .then(result => {
              notification.gravatar = result.avatar;
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        } else {
          resolve();
        }
      });
    },

    handleClick: function(item) {
      if (item.func) item.func();
      else if (item.url) {
        if (item.url != this.$router.currentRoute.path)
          this.$router.push(item.url);
      }
    },

    closeNotification() {
      this.notification_snackbar = false;
      this.notification = null;
    },

    ackNotification() {
      notificationsManager
        .ackNotification(this.notification.id, false)
        .then(() => {
          this.notification.ref.acked = true;
          localStorageService.setNotifications(
            JSON.stringify(this.$store.state.user.notifications)
          );
          this.incCounterRecomputer++;
          this.closeNotification();
        })
        .catch(err => {
          console.log(err);
        });
    },

    removeNotification() {
      notificationsManager
        .ackNotification(this.notification.id, true)
        .then(() => {
          for (
            let n = 0;
            n < this.$store.state.user.notifications.length;
            n++
          ) {
            if (
              this.$store.state.user.notifications[n].id == this.notification.id
            ) {
              this.$store.state.user.notifications.splice(n, 1);
              break;
            }
          }

          localStorageService.setNotifications(
            JSON.stringify(this.$store.state.user.notifications)
          );
          this.incCounterRecomputer++;
          this.closeNotification();
        })
        .catch(err => {
          console.log(err);
        });
    },

    showNotification(notification) {
      this.notification_snackbar = true;
      this.notification = notification;
      if (notification.severity == 0) this.notification_severity = "orange";
      else if (notification.severity == 1)
        this.notification_severity = "purple";
      else if (notification.severity == 2) this.notification_severity = "green";
    },

    processNotification(notification) {
      if (!notification.valid) return;
      if (notification.avatar_id) {
        this.checkGravatar(notification)
          .then(() => {
            this.showNotification(notification);
          })
          .catch(err => {
            console.log(err);
            this.showNotification(notification);
          });
      } else {
        this.showNotification(notification);
      }
    },

    hasValidNotifications: function() {
      if (
        !this.notifications ||
        !this.notifications.length ||
        !this.notifications[0].valid
      )
        return false;
      else return true;
    },

    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),

    logout: function() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          this.$router.push("/login");
        });
    }
  },

  computed: {
    ...mapState(["drawer"]),
    notifications: function() {
      this.incCounterRecomputer;
      let not = [];
      if (
        this.$store.state.user.notifications &&
        this.$store.state.user.notifications.notifications &&
        this.$store.state.user.notifications.notifications.length > 0
      ) {
        for (
          let n = 0;
          n < this.$store.state.user.notifications.notifications.length;
          n++
        ) {
          let ntf = this.$store.state.user.notifications.notifications[n];
          not.push({ ...ntf, title: ntf.name, valid: true, ref: ntf });
        }
      }

      if (
        this.$store.state.user.notifications &&
        this.$store.state.user.notifications.loans &&
        this.$store.state.user.notifications.loans.length > 0
      ) {
        for (
          let n = 0;
          n < this.$store.state.user.notifications.loans.length;
          n++
        ) {
          let ntf = this.$store.state.user.notifications.loans[n];
          not.push({
            loan: true,
            title: this.$t("loan-notifications", {
              business_name: ntf.business_name
                ? ntf.business_name
                : ntf.first_name + " " + ntf.last_name,
              id: ntf.id
            }),
            valid: true,
            ref: ntf
          });
        }
      }

      if (not.length == 0) {
        return [{ title: this.$t("header-nonotifications"), valid: false }];
      } else {
        return not;
      }
    },

    notificationBadgeNumber: function() {
      let n = 0;
      if (this.notifications) {
        for (let x = 0; x < this.notifications.length; x++) {
          if (
            (this.notifications[x].valid && !this.notifications[x].ref.acked) ||
            this.notifications[x].loan
          )
            n++;
        }
      }

      return n;
    }
  }
};
</script>
