var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"dark":"","app":"","src":_vm.background_image},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(10,10,10,.5), rgba(90,90,90,.8)"}},'v-img',props,false))]}}])},[_c('v-snackbar',{attrs:{"timeout":_vm.notification_timeout,"color":_vm.notification_severity,"top":""},model:{value:(_vm.notification_snackbar),callback:function ($$v) {_vm.notification_snackbar=$$v},expression:"notification_snackbar"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[(!_vm.notification || !_vm.notification.avatar_id)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-bell")]):_vm._e(),(_vm.notification && _vm.notification.avatar_id)?_c('div',[_c('div',{staticClass:"profile-container mr-2"},[(_vm.notification.gravatar)?_c('div',{staticClass:"profile-gravatar",domProps:{"innerHTML":_vm._s(_vm.buildGravatar(_vm.notification))}}):_vm._e()])]):_vm._e()],1),_c('v-col',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.notification ? _vm.notification.content : "")+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[(
            _vm.notification
              ? !_vm.notification.persistent && !_vm.notification.acked
              : false
          )?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"light-green"},on:{"click":function($event){return _vm.ackNotification()}}},[_vm._v("mdi-check")]):_vm._e(),(_vm.notification ? !_vm.notification.persistent : false)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.removeNotification()}}},[_vm._v("mdi-delete-circle-outline")]):_vm._e(),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"white"},on:{"click":function($event){return _vm.closeNotification()}}},[_vm._v("mdi-close-circle")])],1)],1)],1),_c('v-btn',{attrs:{"fab":"","x-small":""},on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown
        ? _vm.setDrawer(!_vm.drawer)
        : _vm.$emit('input', !_vm.value)}}},[(_vm.value)?_c('v-icon',[_vm._v(" mdi-view-quilt ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1),_c('span',{staticClass:"yeast-logo-span"},[_c('img',{staticClass:"forno-logo",attrs:{"src":require("../assets/forno_logo_bianco.png")}})]),_c('span',{staticClass:"header-copyright"},[_vm._v(_vm._s(_vm.$t("yeast-copy", { version: _vm.packageInfo.version })))]),_c('v-spacer'),_c('div',{staticClass:"mx-3"}),_c('span',{staticClass:"header-welcome"},[_vm._v(" "+_vm._s(_vm.$t("yeast-welcome", { name: _vm.$store.state.user.first_name }))+" ")]),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[(_vm.hasValidNotifications())?_c('v-badge',{attrs:{"color":"red","overlap":"","bordered":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.notificationBadgeNumber))])]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("mdi-bell")])],1):_vm._e(),(!_vm.hasValidNotifications())?_c('v-icon',[_vm._v("mdi-bell")]):_vm._e()],1)]}}])},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('div',_vm._l((_vm.notifications),function(n,i){return _c('app-bar-item',{key:("item-" + i)},[_c('v-list-item-title',{on:{"click":function($event){return _vm.processNotification(n)}}},[(!n.acked && n.valid)?_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"red"}},[_vm._v("mdi-circle")]):_vm._e(),_vm._v(_vm._s(n.title))],1)],1)}),1)])],1),(!_vm.$store.state.spooler_online)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-printer-off ")])]}}],null,false,1213526454)},[_c('span',[_vm._v("Spooler di stampa offline")])]):_vm._e(),(_vm.$store.state.spooler_online)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"orange","overlap":"","bordered":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$store.state.spooler_num_of_copies))])]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("mdi-printer-check")])],1)],1)]}}],null,false,998742623)},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('div',_vm._l(([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),function(n,i){return _c('app-bar-item',{key:("item-" + i)},[_c('v-list-item-title',{on:{"click":function($event){return _vm.selectPrinterSpoolCopies(n)}}},[_vm._v(_vm._s(n))])],1)}),1)])],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_vm._l((_vm.profile),function(p,i){return [(p.divider)?_c('v-divider',{key:("divider-" + i),staticClass:"mb-2 mt-2"}):_c('app-bar-item',{key:("item-" + i)},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(p.title)},on:{"click":function($event){return _vm.handleClick(p)}}})],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }